export const proxy_url = 'http://sitemapper.edx.su/proxy'
export const useProxy = false
export const mode = process.env.NODE_ENV

if (!["production","development"].includes(mode))
  throw("Некорректный NODE_ENV: " + mode)
if ((mode === "production") && useProxy)
  throw("Ошибка: попытка использовать proxy в production")

const bestdoctorResources = {
  development: "https://beta.bestdoctor.ru/api/resources/",
  production: "https://my.bestdoctor.ru/api/resources/"
}
const margoResources = {
  development: "https://rosbankinsurance.ru/bitapi/api/resources",
  production: "https://margo.bestdoctor.ru/api/resources/"
}
export const bestdoctorResourcesUrl = bestdoctorResources[mode]
export const margoResourcesUrl = margoResources[mode]


