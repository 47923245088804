import React from 'react'
import './Button.sass'

export default function Button({ children, onlytext, inactive, className = '', ...attrs }) {
  return (
    <button className={'button' 
      + (onlytext? ' button_onlytext':'')
      + (inactive? ' button_inactive':'')
      + (className? (' ' + className) : '')
      } {...attrs}>{children}</button>
  )
}
