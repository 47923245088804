import React, { useContext } from 'react'
import './Header.sass'
import { MainContext } from '../../context/main'
import Button from '../UI/Button/Button';

export default function Header(props) {
  const context = useContext(MainContext)
  const { token } = context

  return (
    <header className="header">
      <div className="wrapper">
        <div className="header__inner">
          <div className="header__left">
            {props.children}
          </div>
          <div className="header__logo" onClick={()=>token?context.setRoute('/main'):context.setRoute('/auth')}>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path d="M21.7524 12.2926H14.7558C14.4543 12.2926 14.3036 12.6561 14.5164 12.869L19.4645 17.8171L17.6733 19.6084L12.734 14.6691C12.5212 14.4563 12.1576 14.607 12.1576 14.9085V21.8962H9.62142V14.9085C9.62142 14.607 9.25785 14.4563 9.04502 14.6691L4.07913 19.6173L2.28786 17.826L7.23602 12.8778C7.44884 12.665 7.29809 12.3014 6.99659 12.3014H0V9.76527H6.99659C7.29809 9.76527 7.44884 9.4017 7.23602 9.18887L2.28786 4.23184L4.07913 2.44057L9.01842 7.37987C9.23124 7.59269 9.59482 7.44194 9.59482 7.14044V0.15271H12.131V7.14044C12.131 7.44194 12.4945 7.59269 12.7074 7.37987L17.6467 2.44057L19.4645 4.23184L14.5164 9.18C14.3036 9.39283 14.4543 9.7564 14.7558 9.7564H21.7524V12.2926Z" fill="#5678AA" />
            </svg>
            BestInsure
          </div>
          <div className="header__right">
            {
              (token?.refresh) ?
                (<Button onClick={context.logout} onlytext={true}>Выйти</Button>)
                : ''
            }
          </div>
        </div>
      </div>
    </header>
  )
}
