import React, { useContext, useEffect, useMemo, useRef } from 'react'
import classes from './Step2.module.sass'
import { RegisterContext } from '../../../context/register'

export default function Step1({ next }) {
  const context = useContext(RegisterContext)

  const inputs = [null, null, null, null]
  inputs[0] = useRef()
  inputs[1] = useRef()
  inputs[2] = useRef()
  inputs[3] = useRef()
  const tel = useMemo(() => context.tel, [context])
  const time = useMemo(() => {
    let s = context.wait_seconds % 60
    let m = (context.wait_seconds - s) / 60
    return m + ':' + (s + '').padStart(2, '0')
  }, [context])

  useEffect(()=>inputs[0].current.select(),[])

  const selectNext = (e) => {
    if (!e.target.value) return
    let index = inputs.findIndex(el => el && el.current === e.target)
    if (index === 3) sendCode()
    if (index === -1 || index > 3) return
    if (inputs[index + 1]) {
      inputs[index + 1].current.focus()
    }
  }

  const getCode = () => inputs.reduce((a, b) => a + '' + b.current.value, '')

  const sendCode = async () => {
    const code = getCode()
    if (!code || code.length !== 4) return
    await window.proxyfetch(context.nextLink, {
      "method": "POST",
      "headers": {
        "Accept": "application/vnd.bestdoctor.v1-common.moby:3",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "code": code })
    })
      .then(async res => {
        if (!res.ok) throw (await res.json()).message
        return res.json()
      })
      .then(json => {
        let obj;
        console.log(json);
        try {
          obj = json.data.allowed_steps.find(el => el.type === "patient_registration")
        } catch (err) {
          throw (json)
        }
        if (!obj) {
          obj = json.data.allowed_steps.find(el => ["set_password", "set_password_with_passport"].includes(el.type))
          next({ nextLink: obj.url, access_token: obj.access_token, increment: 2, passport_required: obj.type === "set_password_with_passport" })
        } else {
          next({ nextLink: obj.url, access_token: obj.access_token, documents: obj.documents })
        }
        console.log(obj)
      })
      .catch(err => {
        console.error(err);
        alert(err?.message || err);
      })
  }

  return (
    <div className={`step ${classes.step2}`}>
      <h1 className="step__title">Введите код из СМС</h1>
      <div className="step__subtitle">{tel}</div>
      <form onSubmit={(e)=>e.preventDefault()} className={classes.step2__digits}>
        <input onKeyDown={e=>e.key === "Enter"? e.preventDefault(): false} onKeyUp={window.formStep} className={classes.step2__digit} type="text" ref={inputs[0]} inputMode='numeric' mask="#" onInput={selectNext} onFocus={(e) => e.target.select()} maxLength={1} />
        <input onKeyDown={e=>e.key === "Enter"? e.preventDefault(): false} onKeyUp={window.formStep} className={classes.step2__digit} type="text" ref={inputs[1]} inputMode='numeric' mask="#" onInput={selectNext} onFocus={(e) => e.target.select()} maxLength={1} />
        <input onKeyDown={e=>e.key === "Enter"? e.preventDefault(): false} onKeyUp={window.formStep} className={classes.step2__digit} type="text" ref={inputs[2]} inputMode='numeric' mask="#" onInput={selectNext} onFocus={(e) => e.target.select()} maxLength={1} />
        <input onKeyDown={e=>e.key === "Enter"? e.preventDefault(): false} onKeyUp={window.formStep} className={classes.step2__digit} type="text" ref={inputs[3]} inputMode='numeric' mask="#" onInput={selectNext} onFocus={(e) => e.target.select()} maxLength={1} />
      </form>
      {
        time === '0:00' ?
          <button className={classes.step2__link} onClick={context.requestCode}>Получить новый код</button>
          : <div className={classes.step2__timer}>Получить новый код можно через&nbsp;{time}</div>
      }
    </div>
  )
}
