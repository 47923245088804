import React, { useRef } from 'react'
import s from './File.module.sass'

export default function FileViewer({ name, ext, remove, ...args }) {
  const file = useRef()

  return (
    <div className={s.file} {...args} ref={file}>
      <button className={s.file__remove} onClick={remove}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1_1291)">
            <circle cx="12" cy="12" r="12" />
            <path d="M11.7052 13.7677C11.8679 13.605 12.1317 13.605 12.2945 13.7677L16.5666 18.0398L18.0397 16.5667L13.7676 12.2946C13.6049 12.1319 13.6049 11.868 13.7676 11.7053L18.0397 7.43322L16.5666 5.96008L12.2945 10.2322C12.1317 10.3949 11.8679 10.3949 11.7052 10.2322L7.4331 5.96008L5.95996 7.43322L10.2321 11.7053C10.3948 11.868 10.3948 12.1319 10.2321 12.2946L5.95996 16.5667L7.4331 18.0398L11.7052 13.7677Z" fill="currentColor" stroke="none" />
          </g>
          <defs>
            <clipPath id="clip0_1_1291">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <div className={s.file__icon}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.8284 6.82843C18.4065 7.40649 18.6955 7.69552 18.8478 8.06306C19 8.4306 19 8.83935 19 9.65685L19 17C19 18.8856 19 19.8284 18.4142 20.4142C17.8284 21 16.8856 21 15 21H9C7.11438 21 6.17157 21 5.58579 20.4142C5 19.8284 5 18.8856 5 17L5 7C5 5.11438 5 4.17157 5.58579 3.58579C6.17157 3 7.11438 3 9 3H12.3431C13.1606 3 13.5694 3 13.9369 3.15224C14.3045 3.30448 14.5935 3.59351 15.1716 4.17157L17.8284 6.82843Z" fill="currentColor" stroke="none" />
          <path d="M17.8284 6.82843C18.4065 7.40649 18.6955 7.69552 18.8478 8.06306C19 8.4306 19 8.83935 19 9.65685L19 17C19 18.8856 19 19.8284 18.4142 20.4142C17.8284 21 16.8856 21 15 21H9C7.11438 21 6.17157 21 5.58579 20.4142C5 19.8284 5 18.8856 5 17L5 7C5 5.11438 5 4.17157 5.58579 3.58579C6.17157 3 7.11438 3 9 3H12.3431C13.1606 3 13.5694 3 13.9369 3.15224C14.3045 3.30448 14.5935 3.59351 15.1716 4.17157L17.8284 6.82843Z" strokeLinejoin="round" />
        </svg>
        <div className={s.file__ext + (ext?.length > 3 ? ` ${s.file__ext_sm}` : '')}>{ext}</div>
      </div>
      <div className={s.file__name + (name?.length > 10 ? ` ${s.file__name_sm}` : '') + (name?.length > 20 ? ` ${s.file__name_smallest}` : '')}>{name}</div>
    </div>
  )
}
