import React, { forwardRef, useCallback, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import './Dropzone.sass'
import FileViewer from './File/FileViewer'

export default forwardRef(({ name = "files", required = null }, ref) => {
  const [files, setFiles] = useState([])

  const removeFile = useEvent((file) => {
    const index = files.indexOf(file)
    if (index === -1) return

    setFiles(files.filter((el, i) => i !== index))

    acceptedFiles.splice(index, 1)
    const dt = new DataTransfer()
    for (let i = 0; i < inputRef.current.files.length; i++) {
      if (i === index)
        continue
      dt.items.add(inputRef.current.files[i])
    }
    inputRef.current.files = dt.files
  })

  const onDrop = useCallback((acceptedFiles, arg2, isDrop) => {
    const dt = new DataTransfer()

    let arr = [...inputRef.current.files]
    acceptedFiles = acceptedFiles.filter(el => !arr.find(f => f.name === el.name))
    for (let f of acceptedFiles)
      arr.push(f)

    for (let i = 0; i < arr.length; i++)
      dt.items.add(arr[i])

    let v = arr.map((el, index) => {
      let name = el.name.replace(/\.[^.]*$/, ''),
        ext
      if (el.type.match(/^image\//))
        ext = el.type.replace('image/', '')
      else
        ext = el.name.match(/\.[^.]*$/) ? el.name.match(/\.[^.]*$/)[0].replace('.', '') : '?'
      if (ext === 'jpeg')
        ext = 'jpg'
      const file = {
        name, ext, key: el.name + ' | ' + el.size + ' | ' + el.lastModified,
        remove: (e) => { e.stopPropagation(); removeFile(file) }
      }
      return file
    })



    setTimeout(() => {
      setFiles(v)
      inputRef.current.files = dt.files
    })

  }, [])

  const { getRootProps, getInputProps, acceptedFiles, inputRef } = useDropzone({
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/gif': ['.gif'],
      'image/png': ['.png'],
      'image/heif': ['.heif'],
      'image/heic': ['.heic'],
    },
    minSize: 0,
    maxSize: 1024 * 1024 * 10,
    onDrop
  })

  useImperativeHandle(ref, () => ({
    clearFiles() {
      acceptedFiles.length = 0
      acceptedFiles.splice(0, acceptedFiles.length)
      setFiles([])
      inputRef.current.value = ''
    }
  }))

  return (
    <div {...getRootProps()} className='dropzone'>
      <input {...getInputProps()} name={name} multiple required={required} tabIndex={-1} className='dropzone__input' />
      {
        (!files.length) ?
          <p className='dropzone__text'>Можно перетянуть фото сюда или
            <span className='gray4'> выбрать на компьютере</span>, подойдут файлы формата jpg, png, gif и live-photo.</p>
          :
          <div className='dropzone__grid'>
            {files.map(f => <FileViewer {...f} />)}
          </div>

      }
    </div>
  )
})

function useEvent(handler) {
  const handlerRef = useRef(null);

  useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  return useCallback((...args) => {
    const fn = handlerRef.current;
    return fn(...args);
  }, []);
}
