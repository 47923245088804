import React, { useContext, useRef } from 'react'
import { RegisterContext } from '../../../context/register'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'

export default function Step4({ next }) {
  const context = useContext(RegisterContext)
  const form = useRef()
  const register = () => {
    if (!form.current.checkValidity())
      return window.formMessage(form.current)

    let pws = Array.from(form.current.querySelectorAll('input[type=password]'))
    console.log(pws)
    let set = new Set(pws.map(el => el.value))
    console.log(set)
    if (set.size > 1) return alert('Пароли не совпадают')

    const data = new FormData(form.current)
    let object = {}
    data.forEach((value, key) => object[key] = value)
    let json = JSON.stringify(object)
    let tmp_status
    window.proxyfetch(context.nextLink, {
      "method": "POST",
      "headers": {
        "Accept": "application/vnd.bestdoctor.v1-common.moby:3",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + context.access_token
      },
      body: json
    })
      .then(async res => {
        tmp_status = res.status
        console.log(tmp_status)
        if (!res.ok) throw (await res.json()).message
        console.log(res.status)
        return res.json()
      })
      .then(json => {
        console.log(json || tmp_status)
        next({
          finish: true,
          token: {
            access: json.data.access_token,
            refresh: json.data.refresh_token,
          }
        })
      })
      .catch(err => {
        err = err || tmp_status
        console.error(err)
        alert(err)
      })
  }
  return (
    <div className="step step4">
      <h1 className="step__title">
        {
          context.reset ?
            "Создание пароля"
            : "Регистрация"
        }
      </h1>
      <form ref={form} className="step3__form" onSubmit={(e) => e.preventDefault()}>
        <Input type="password" clearable={true} required="required" label="Пароль" name="password" />
        <Input type="password" clearable={true} required="required" label="Подтвердите пароль" name="repeat_password" />
        {
          context.passport_required ?
            <Input type="text" clearable={true} required="required" label="Номер паспорта (4 последних символа)" maxLength="4" name="passport_last_symbols" />
            : ''
        }
        <Button className="step__button button_submit" onClick={register}>Продолжить</Button>
      </form>

    </div>
  )
}
