import React, { useEffect, useRef, useState } from 'react'
import { MaskInput } from 'maska'
import './Input.sass'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ruRU } from "@mui/x-date-pickers/locales/ruRU"
//eslint-disable-next-line
import ru from 'dayjs/locale/ru'
import { DesktopDatePicker } from '@mui/x-date-pickers';


export default function Input({ label, type = 'text', mask = '', clearable, checkFn, datepicker = false, changeFn, ...args }) {
  const input = useRef()
  const maskObject = useRef()
  const Tag = label ? 'label' : 'div'
  const [realtype, setRealType] = useState(type)
  const [value, setValue] = useState('')
  const [error, setError] = useState('')
  
  if ((realtype === "password") && (checkFn === undefined)) {
    checkFn = (v) => {
      if (v.length < 8)
        return 'Не менее 8 символов'
      if (!v.match(/[0-9]/))
        return 'Пароль должен содержать хотя бы одну цифру'
      if (v.toUpperCase() === v)
        return 'Пароль должен содержать хотя бы одну строчную букву'
      if (v.toLowerCase() === v)
        return 'Пароль должен содержать хотя бы одну заглавную букву'
      return ''
    }

  }

  useEffect(() => {
    input.current.classList.add('input_inited')
    if (maskObject.current) return
    if (!input.current.hasAttribute('placeholder'))
      input.current.setAttribute('placeholder', " ")
    if (mask) {
      maskObject.current = new MaskInput(input.current, { mask });

      if (mask.match(/^\+# \(/)) {
        let inputListener = (e) => {
          if (!e.isTrusted) return
          let v = e.target.value
          if (maskObject.current && (v === ''))
            destroyMask()
        }

        let beforeListener = (e) => {
          if (e?.data?.length > 1) {
            let l = maskObject.current.options.mask.replace(/[^0-9#]/g, "").length,
              d = e.data.replace(/[^0-9]/g, ""),
              dl = d.length
            if (dl > l)
              destroyMask()
            else if (dl === l) {
              console.log('here1')
              console.log(e.data[0])
              if (e.data[0] !== '+') {
                input.current.value = ''
                destroyMask()
              }
            }
          }
        }

        function destroyMask() {
          maskObject.current.destroy()
          setTimeout(() =>
            new MaskInput(input.current, {
              mask: (value) => ''.padStart(value.length + 1, '*'),
              tokens: {
                //eslint-disable-next-line
                "*": { pattern: /[0-9.\-+\(\)]/ }
              }
            }))
          maskObject.current = false
          input.current.removeEventListener('beforeinput', beforeListener)
          input.current.removeEventListener('input', inputListener)
          input.current.removeAttribute('pattern')
        }

        input.current.addEventListener('beforeinput', beforeListener)
        input.current.addEventListener('input', inputListener)
      }
    }
  }, [])
  const onfocus = (e) => {
    if (!mask || !mask.match(/^\+# \(/) || !maskObject.current) return
    if (!e.target.value.trim())
      e.target.value = '+7 ('
  }
  const onblur = (e) => {
    if (!mask || !mask.match(/^\+# \(/) || !maskObject.current) return
    if (e.target.value.trim() === '+7 (')
      e.target.value = ''
  }

  const toggleType = (e) => {
    e.preventDefault()
    if (realtype === "password")
      setRealType("text")
    else
      setRealType("password")
  }

  const change = (e) => {
    let v = e.target.value
    
    if (changeFn)
      changeFn(v)
    if (checkFn) {
      let error = checkFn(v)
      setError(error)
      if (error) {
        e.target.setAttribute('pattern', `.{${v.length + 100}}`)
      } else {
        e.target.removeAttribute('pattern')
      }
    }
    if (!v)
      console.trace()
    setValue(v)
  }
  return (
    <Tag className="inwrapper">
      <input value={value} onInput={change} onFocus={onfocus} onBlur={onblur} ref={input} className={`input ${clearable ? 'input_clearable' : ''} ${(type === "password") ? 'input_pw' : ''}`} type={realtype} {...args} onKeyUp={window.formStep} onKeyDown={e => e.key === "Enter" ? e.preventDefault() : false} />
      {
        label ? <span className="inwrapper__label">{label}</span> : ''
      }
      <div className="inwrapper__actions">
        {
          datepicker ?
            <PickerWithButtonField setValue={setValue} />
            : ''
        }
        {
          clearable ?
            <button className="inwrapper__clear" onClick={(e) => { e.preventDefault(); input.current.value = ''; console.log('here'); console.log(e) }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM7.05025 8.46447C6.65973 8.07394 6.65973 7.44078 7.05025 7.05025C7.44078 6.65973 8.07394 6.65973 8.46447 7.05025L11.6464 10.2322C11.8417 10.4275 12.1583 10.4275 12.3536 10.2322L15.5355 7.05025C15.9261 6.65973 16.5592 6.65973 16.9497 7.05025C17.3403 7.44078 17.3403 8.07394 16.9497 8.46447L13.7678 11.6464C13.5725 11.8417 13.5725 12.1583 13.7678 12.3536L16.9497 15.5355C17.3403 15.9261 17.3403 16.5592 16.9497 16.9497C16.5592 17.3403 15.9261 17.3403 15.5355 16.9497L12.3536 13.7678C12.1583 13.5725 11.8417 13.5725 11.6464 13.7678L8.46447 16.9497C8.07394 17.3403 7.44078 17.3403 7.05025 16.9497C6.65973 16.5592 6.65973 15.9261 7.05025 15.5355L10.2322 12.3536C10.4275 12.1583 10.4275 11.8417 10.2322 11.6464L7.05025 8.46447Z" />
              </svg>
            </button>
            : ''
        }
        {
          type === "password" ?
            <button className='inwrapper__show' onClick={toggleType}>
              {
                realtype === "password" ?
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M20.2929 21.7071L16.6755 18.0896C15.2507 18.9232 13.6638 19.5 12 19.5C6 19.5 1 12 1 12C1 12 2.84677 9.2298 5.64242 7.05658L2.29289 3.70706L3.70711 2.29285L6.30711 4.89285L6.29289 4.90706L19.7929 18.4071L19.8071 18.3928L21.7071 20.2928L20.2929 21.7071ZM19.8602 15.6459C21.7958 13.8062 23 12 23 12C23 12 18 4.49995 12 4.49995C11.0441 4.49995 10.1136 4.69031 9.22464 5.01038L12.2202 8.00591C14.254 8.11624 15.8837 9.74593 15.994 11.7798L19.8602 15.6459ZM14.032 15.4461L12.518 13.9322C12.3528 13.9764 12.1792 14 12 14C10.8954 14 10 13.1045 10 12C10 11.8208 10.0236 11.6471 10.0677 11.4819L8.55382 9.96799C8.20193 10.5635 8 11.2581 8 12C8 14.2091 9.79086 16 12 16C12.7418 16 13.4365 15.798 14.032 15.4461Z" />
                  </svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12 4.5C18 4.5 23 12 23 12C23 12 18 19.5 12 19.5C6 19.5 1 12 1 12C1 12 6 4.5 12 4.5ZM16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12ZM12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" fill="#8f95a3" />
                  </svg>
              }
            </button>
            : ''
        }
      </div>
      {
        error ?
          <div className="inwrapper__error">{error}</div>
          : ''
      }
    </Tag>

  )
}


function ButtonField(props) {
  const {
    setOpen,
    //label,
    id,
    disabled,
    InputProps: { ref } = {},
    inputProps: { 'aria-label': ariaLabel } = {},
  } = props;
  const [opened, setOpened] = useState(false)

  return (
    <button
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={(e) => { e.preventDefault(); setOpen?.((prev) => { setOpened(!prev); return !prev }); }}
      className={"inwrapper__icon" + (opened ? " inwrapper__icon_active" : '')}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M7 10.5V13.5H10V10.5H7Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M6 1.5H8.5V3H15.5V1.5H18V3C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34315 4.34315 3 6 3V1.5ZM5.5 18V9H18.5V18C18.5 18.2761 18.2761 18.5 18 18.5H6C5.72386 18.5 5.5 18.2761 5.5 18Z" />
      </svg>
    </button>
  );
}

function ButtonDatePicker(props) {
  const [open, setOpen] = React.useState(false);

  return (
    <DesktopDatePicker
      slots={{ field: ButtonField, ...props.slots }}
      slotProps={{ field: { setOpen } }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      adapterLocale={ruRU}
    />
  );
}

function PickerWithButtonField({ setValue }) {
  const [v, setV] = React.useState(null);
  function onChange(newValue) {
    setV(newValue);
    setValue(
      `${(newValue.$D + '').padStart(2, '0')}.${(newValue.$M + 1 + '').padStart(2, '0')}.${(newValue.$y + '').padStart(4, '0')}`
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru' localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
      <ButtonDatePicker
        label={v == null ? null : v.format('DD.MM.YYYY')}
        value={v}
        onChange={onChange}
      />
    </LocalizationProvider>
  );
}