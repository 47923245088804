import React, { useEffect, useRef } from 'react'
import './SmoothSwitch.sass'
import Button from '../Button/Button'

export default function SmoothSwitch({ switched, children, msg = {} }) {
  let main = useRef()
  useEffect(() => {
    if (switched) {
      main.current.style.setProperty('height', main.current.offsetHeight + 'px')
      window.requestAnimationFrame(() => {
        main.current.style.setProperty('transition', 'opacity .1s ease-out, height 1s ease')
        main.current.style.setProperty('opacity', '0')
        main.current.style.setProperty('height', '0px')
      })
    } else {
      main.current.removeAttribute('style')
    }
  }, [switched])

  return (
    <div className={"smoothswitch" + (switched ? ' smoothswitch_switched' : '')}>
      <div className="smoothswitch__main" ref={main}>{children}</div>
      <div className="smoothswitch__switch">
        <div className="switchmsg">
          <div className="switchmsg__icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 109 108" fill="none">
              <g filter="url(#filter0_ddd_85_217)">
                <path d="M98.5 50C98.5 74.3005 78.8005 94 54.5 94C30.1995 94 10.5 74.3005 10.5 50C10.5 25.6995 30.1995 6 54.5 6C78.8005 6 98.5 25.6995 98.5 50Z" fill="#F1F2F4" />
                <path d="M98.5 50C98.5 74.3005 78.8005 94 54.5 94C30.1995 94 10.5 74.3005 10.5 50C10.5 25.6995 30.1995 6 54.5 6C78.8005 6 98.5 25.6995 98.5 50Z" fill="url(#paint0_linear_85_217)" />
              </g>
              <path fillRule="evenodd" clipRule="evenodd" d="M81.3282 36.8284L49.3282 68.8284C47.7661 70.3905 45.2335 70.3905 43.6714 68.8284L27.6714 52.8284L33.3282 47.1716L46.4998 60.3431L75.6714 31.1716L81.3282 36.8284Z" fill="#65BBBB" />
              <defs>
                <filter id="filter0_ddd_85_217" x="0.5" y="0" width="108" height="108" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset dy="1" />
                  <feGaussianBlur stdDeviation="0.5" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.254902 0 0 0 0 0.262745 0 0 0 0 0.305882 0 0 0 0.04 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_85_217" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect2_dropShadow_85_217" />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="6" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.254902 0 0 0 0 0.262745 0 0 0 0 0.305882 0 0 0 0.12 0" />
                  <feBlend mode="normal" in2="effect1_dropShadow_85_217" result="effect2_dropShadow_85_217" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="effect3_dropShadow_85_217" />
                  <feOffset />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.254902 0 0 0 0 0.262745 0 0 0 0 0.305882 0 0 0 0.04 0" />
                  <feBlend mode="normal" in2="effect2_dropShadow_85_217" result="effect3_dropShadow_85_217" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_85_217" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_85_217" x1="-21.1" y1="-7.4074" x2="111.273" y2="28.9778" gradientUnits="userSpaceOnUse">
                  <stop stopColor="white" />
                  <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <h2 className="switchmsg__title">{msg.title}</h2>
          <p className="switchmsg__text">{msg.text}</p>
          <Button onClick={msg.action}>{msg.button}</Button>
        </div>
      </div>
    </div>
  )
}
