import React, { useEffect, useRef, useState } from 'react'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import Dropzone from '../../UI/Dropzone/Dropzone'
import Popup from '../../UI/Popup/Popup'
import SmoothSwitch from '../../UI/SmoothSwitch/SmoothSwitch'
import { MainContext } from '../../../context/main'
import { useContext } from 'react'

export default function MainPopup({ active, setpopupShown }) {
  let form = useRef()
  let dropzone = useRef()
  const context = useContext(MainContext);
  let [sended, setSended] = useState(false)
  const submit = async () => {
    if (!form.current.checkValidity()) 
      return window.formMessage(form.current)
    let data = new FormData(form.current)
    let object = {}
    data.forEach((value, key) => object[key] = data.getAll(key))
    console.log(object)
    let status
    if (!context.margoUrls.insurance_events_url) {
      context.setLoader((prev) => prev + 1)
      await new Promise(r => setTimeout(r, 3000))
      if (!context.margoUrls.insurance_events_url) {
        context.setLoader((prev) => prev - 1)
        return alert("URL отправки формы отсутствует")
      }
    }
    context.makeRequest({
      url: context.margoUrls.insurance_events_url,
      method: "POST",
      json: false,
      body: data
    }).then(async res => {
      console.log(res.status)
      if (res.ok) {
        setSended(true)
        form.current.reset()
        dropzone.current.clearFiles()
      } else {
        status = res.status
        throw (await res.json())
      }
    }).catch(err => {
      console.error(err)
      let str = (typeof (err) === 'string' ? err : (err?.message || err?.data?.message))
      alert(str || `Ошибка, что-то пошло не так\n(${status})`)
    })
  }

  useEffect(() => { if (active) setSended(false); }, [active])


  return (
    <Popup active={active} setpopupShown={setpopupShown}>
      <SmoothSwitch switched={sended} msg={{ title: "Обращение отправлено", text: "Мы обработаем обращение и свяжемся с вами", button: "Хорошо", action: () => setpopupShown(false) }}>
        <form ref={form} onSubmit={(e) => e.preventDefault()}>
          <div className="popup__title">Расскажите о&nbsp;проблеме</div>
          <Input required type="text" name="comment" label="Комментарий" />
          <Input required type="text" pattern="[a-zа-я0-9._%+\-]+@[a-zа-я0-9.\-]+\.[а-яa-z]{2,7}$" name="email" label="Электронная почта" />
          <div className="popup__dropzone">
            <Dropzone ref={dropzone} required={false} name="files[]" />
          </div>
          <div className="popup__buttons">
            <Button onClick={submit}>Отправить</Button>
          </div>
        </form>
      </SmoothSwitch>
    </Popup >
  )
}
