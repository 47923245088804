import React from 'react'
import './CheckBox.sass'

export default function CheckBox({ children, ...args }) {
  const changeValue = (e) => {
    if (e.key !== "Tab")
      e.preventDefault()
    if (['Enter','Space'].includes(e.key))
      e.target.click()
  }
  return (
    <label className="checkbox">
      <input className='checkbox__input' type="checkbox" {...args} />
      <div className="checkbox__check" tabIndex={0} onKeyDown={changeValue}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.499 60.499"><path d="M58.968 12.734a5.92 5.92 0 0 0-8.359-.415L24.481 35.975 9.991 22.218a5.92 5.92 0 0 0-8.366.218 5.92 5.92 0 0 0 .218 8.365l18.208 17.283a5.89 5.89 0 0 0 4.072 1.627 5.92 5.92 0 0 0 2.406-.516 5.86 5.86 0 0 0 2.455-1.332l29.568-26.771a5.92 5.92 0 0 0 .416-8.358z" /></svg>
      </div>
      <div className="checkbox__descr">{children}</div>
    </label>
  )
}
