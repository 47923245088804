import React from 'react'
import './Footer.sass'

export default function Footer() {
  return (
      <footer className='footer'>
        <div className="wrapper footer__wrapper">
          <div className="footer__grid">
            <p className="footer__text">BestInsure — страховая компания, создающая технологичные продукты для управления жизнью и здоровьем людей и бизнеса</p>
            <div className="footer__lcolumn">
              <ul className="footer__lcolumn-ul">
                <li className="footer__lc-link">
                  <a className="footer__lc-link-text" href="tel:84997059295">+7 (499) 705-92-95</a>
                  <span className='footer__dimtext'>По вопросам сотрудничества</span>
                </li>
                <li className="footer__lc-link">
                  <a className="footer__lc-link-text" href="tel:88005059295">8 800 505-92-95</a>
                  <span className='footer__dimtext'>Круглосуточная служба поддержки</span>
                </li>
                <li className="footer__lc-link footer__lc-link_lesspace">
                  <a className="footer__lc-link-text" href="maito:info@bestinsure.tech">info@bestinsure.tech</a>
                  <span className='footer__dimtext'>Для вопросов и предложений</span>
                </li>
                <li className="footer__lc-link footer__lc-link_lesspace">
                  <span className="footer__lc-link-text">127&thinsp;015, г.&nbsp;Москва, вн.тер. г.&nbsp;муниципальный округ Савеловский, ул.&nbsp;Вятская, д.&nbsp;27, стр. 15</span>
                  <span className='footer__dimtext'>Адрес юридического лица</span>
                </li>
                <li className="footer__lc-link">
                  <span className="footer__lc-link-text">127&thinsp;015&nbsp;г.&nbsp;Москва, ул.&nbsp;Вятская, д.&nbsp;27, стр.15</span>
                  <span className='footer__dimtext'>Почтовый адрес</span>
                </li>
              </ul>
              <p className="footer__lcolumn-aftertext">
                <span className="footer__dimtext">Офис работает по будням с 9:30 до 18:30</span><br/>
                <span className="footer__dimtext">Обеденный перерыв с 13:00 до 14:00</span>
              </p>
            </div>
            <div className="footer__rcolumn">
              <ul className="footer__rcolumn-linkline">
                <li><a target="_blank" rel="noreferrer" href="https://vc.ru/bestdoctor">VC.RU</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://vk.com/bestdoctor">VK.COM</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://t.me/bestdoctormedia">TELEGRAM</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://ok.ru/group/70000003501952">OK.RU</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://dzen.ru/bestdoctor">Дзен</a></li>
              </ul>
              <div className="footer__linkblock">
                <div className="footer__linkblock-block">
                  <a target="_blank" rel="noreferrer" href="https://bestinsure.tech/management">Руководство и акционеры</a>
                  <a target="_blank" rel="noreferrer" href="https://bestinsure.tech/feedback">Обращение</a>
                  <a target="_blank" rel="noreferrer" href="https://bestinsure.tech/insurance_rules">Правила страхования</a>
                  <a target="_blank" rel="noreferrer" href="https://bestinsure.tech/documents">Общие документы</a>
                </div>
                <div className="footer__linkblock-block">
                  <a target="_blank" rel="noreferrer" href="https://bestinsure.tech/basic_standards">Базовые стандарты</a>
                  <a target="_blank" rel="noreferrer" href="https://bestinsure.tech/license">Лицензии на страхование</a>
                  <a target="_blank" rel="noreferrer" href="https://bestinsure.tech/insurance_portfolio">Передача страхового портфеля</a>
                </div>
              </div>
              <div className="footer__linkblock">
                <div className="footer__linkblock-block">
                  <a target="_blank" rel="noreferrer" href="https://bestinsure.tech/insurance_types">Виды страхования</a>
                  <a target="_blank" rel="noreferrer" href="https://246822.selcdn.ru/insurance_docs/%D0%90%D0%BD%D1%82%D0%B8%D0%BA%D0%BE%D1%80%D1%80%D1%83%D0%BF%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D0%B0%D1%8F%20%D0%BF%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0.pdf">Антикоррупционная политика</a>
                  <a target="_blank" rel="noreferrer" href="https://bestinsure.tech/terms_of_use">Пользовательское соглашение</a>
                  <a className='footer__desktop' target="_blank" rel="noreferrer" href="https://bestinsure.tech/OpenApi">OpenApi</a>
                  <a className='footer__tablet' target="_blank" rel="noreferrer" href="https://bestinsure.tech/rights_protection_methods">Способы защиты прав получателей страховых услуг</a>
                </div>
                <div className="footer__linkblock-block">
                  <a target="_blank" rel="noreferrer" href="https://bestinsure.tech/reporting">Отчетность</a>
                  <a target="_blank" rel="noreferrer" href="https://bestinsure.tech/trade_secret_protection">Защита персональных данных</a>
                  <a className='footer__desktop' target="_blank" rel="noreferrer" href="https://bestinsure.tech/rights_protection_methods">Способы защиты прав получателей страховых услуг</a>
                  <a className='footer__tablet' target="_blank" rel="noreferrer" href="https://bestinsure.tech/OpenApi">OpenApi</a>
                </div>
              </div>
              <div className="footer__linkblock">
                <div className="footer__linkblock-block">
                  <a target="_blank" rel="noreferrer" href="https://hh.ru/employer/2730772">Работать с нами</a>
                  <a target="_blank" rel="noreferrer" href="https://bestinsure.tech/details">Реквизиты</a>
                  <a target="_blank" rel="noreferrer" href="https://bestinsure.tech/sitemap">Карта сайта</a>
                </div>
                <div className="footer__linkblock-block">
                  <a target="_blank" rel="noreferrer" href="https://bestinsure.tech/agents">Реестр агентов и брокеров</a>
                  <a target="_blank" rel="noreferrer" href="https://bestinsure.tech/name_change">Смена наименования</a>
                </div>
              </div>
            </div>
            <div className="footer__divider"></div>
            <p className="footer__aftertext footer__aftertext_left">Лицензии на&nbsp;осуществление страхования&nbsp;СЛ №&thinsp;2733 и&nbsp;СИ №&thinsp;2733 от&nbsp;04.09.2023&nbsp;г. без ограничения срока действия</p>
            <p className="footer__aftertext footer__aftertext_right">Орган, осуществляющий полномочия по&nbsp;контролю и&nbsp;надзору за&nbsp;страховой деятельностью страховых организаций&nbsp;— ЦБ&nbsp;РФ</p>
            <p className="footer__aftertext footer__aftertext_left">Членство в&nbsp;ВСС с&nbsp;17.05.2017&nbsp;г.</p>
            <p className="footer__aftertext footer__aftertext_right footer__aftertext_lastright">ИНН: 2722015673 ОГРН: 1022701130132</p>
            <p className="footer__aftertext footer__aftertext_left footer__aftertext_last">©&nbsp;2022 АО&nbsp;СК «Бестиншур»</p>
          </div>
        </div>
      </footer>
  )
}
