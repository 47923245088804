import React, { useContext, useMemo, useRef } from 'react'
import Input from '../../UI/Input/Input'
import CheckBox from '../../UI/CheckBox/CheckBox'
import { RegisterContext } from '../../../context/register'
import Button from '../../UI/Button/Button'
import classes from './Step3.module.sass'

export default function Step3({ next }) {
  const context = useContext(RegisterContext)
  const links = useMemo(() => {
    let res = {}
    if (!context.documents) return res
    for (let doc of context.documents)
      switch (doc.document_type) {
        case "license_agreement":
          res.license = doc.url;
          break;
        case "personal_data_processing":
          res.processing = doc.url;
          break;
        default:
          break;
      }
    return res
  }, [context.documents])
  const form = useRef()
  const register = () => {
    if (!form.current.checkValidity()) 
      return window.formMessage(form.current)
    
    const data = new FormData(form.current)
    let birthdate = data.get("birth_date")
    if (birthdate) {
      birthdate = birthdate.split('.').reverse().join('-')
      data.set("birth_date", birthdate)
    }

    let object = {}
    data.forEach((value, key) => object[key] = value)
    if (object.consent)
      object.consent = true
    else
      object.consent = false

    let json = JSON.stringify(object)
    console.log(json)
    console.log(context.access_token)
    
    window.proxyfetch(context.nextLink, {
      "method": "POST",
      "headers": {
        "Accept": "application/vnd.bestdoctor.v1-common.moby:3",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${context.access_token}`
      },
      body: json
    })
      .then(async res => {
        if (!res.ok) throw (await res.json()).message
        console.log(res.status)
        return res.json()
      })
      .then(json => {

        try {
          let el = json.data.allowed_steps.find(el => ["set_password","set_password_with_passport"].includes(el.type))
          let nextLink = el.url
          let access_token = el.access_token
          let passport_required = (el.type === "set_password_with_passport")
          next({ nextLink, access_token, passport_required })
        } catch (err) {
          console.error(err)
          throw (json)
        }
      })
      .catch(err => {
        console.error(err)
        alert(err)
      })
  }
  return (
    <div className={`step ${classes.step3 || ''}`}>
      <h1 className="step__title">Регистрация</h1>
      <form ref={form} className={classes.step3__form} onSubmit={(e) => e.preventDefault()}>
        <Input clearable={true} required="required" label="Фамилия" name="last_name" />
        <Input clearable={true} required="required" label="Имя" name="first_name" />
        <Input clearable={true} label="Отчество" name="middle_name" />
        <Input clearable={true} required="required" label="Дата рождения" mask="##.##.####" pattern="[0-9]{2}\.[0-9]{2}\.[0-9]{4}" inputMode="numeric" name="birth_date" datepicker={true} />
        <CheckBox name="consent" required="required">
          Я соглашаюсь на обработку <a target="_blank" rel="noreferrer" href={links.processing}>персональных данных</a> и <a target="_blank" rel="noreferrer" href={links.license}>условия лицензионного соглашения</a>
        </CheckBox>
        <Button className="step__button button_submit" onClick={register}>Продолжить</Button>
      </form>
    </div>
  )
}
