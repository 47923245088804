import React, { useContext, useEffect, useMemo, useState } from 'react'
import '../sass/modules/main.sass'
import Header from '../components/header/Header'
import Button from '../components/UI/Button/Button'
import MainPopup from '../components/Main/MainPopup/MainPopup'
import { MainContext } from '../context/main'
import ItemList from '../components/ItemList/ItemList'
import Footer from '../components/Footer/Footer'
import { margoResourcesUrl } from '../settings'

export default function Main() {

  const [mode, setMode] = useState(0);
  const [popupShown, setpopupShown] = useState(false);
  let [itlinks, setItlinks] = useState({});
  const { profile, urls, makeRequest, updateUrls, pushContext } = useContext(MainContext);

  const title = useMemo(() => {
    switch (mode) {
      case 0: return 'Ваши полисы'
      case 1: return 'Ваши заявления'
      default: return ''
    }
  }, [mode])

  const itemsLink = useMemo(() => {
    return itlinks[mode] || ''
  }, [itlinks, mode])

  const itemsName = useMemo(() => {
    switch (mode) {
      case 0: return 'полисы'//urls.getPolises
      case 1: return 'заявления'//urls.getStatements
      default: return ''
    }
  }, [mode])

  async function updateData() {
    console.log(urls)
    let url = urls.user_me_url
    if (!url)
      url = (await updateUrls()).user_me_url

    makeRequest({ url: urls.user_me_url })
      .then(async res => {
        let json = await res.json()
        console.log(json)
        if (!res.ok)
          throw (json)
        pushContext({
          profile: json.data
        })
      })
      .catch(err => {
        console.error(err)
        let str = (typeof (err) === 'string' ? err : (err?.message || err?.data?.message))
        if (str)
          alert(str)
      })

    makeRequest({ url: margoResourcesUrl, accept: "application/vnd.bestdoctor" })
      .then(async res => {
        let json = await res.json()
        console.log(json)
        if (!res.ok)
          throw (json)
        pushContext({ margoUrls: json.data })
        setItlinks([json.data.insurance_policies_url, json.data.insurance_events_url])
      })
      .catch(err => {
        console.error(err)
        let str = (typeof (err) === 'string' ? err : (err?.message || err?.data?.message))
        if (str)
          alert(str)
      })
  }

  useEffect(() => {
    updateData()
  }, [])

  return (
    <div>
      <Header>
        {/*<Button onlytext={true} onClick={() => setMode(0)} inactive={mode !== 0}>Полисы</Button>*/}
      </Header>
      <main className='main'>
        <div className="wrapper">
          <div className="main__inner">
            <div className="main__headline">
              <h1 className="main__title">{title}</h1>
              <Button className='main__declare' onClick={() => setpopupShown(true)}>Заявить о страховом случае</Button>
            </div>
            <div className="main__info">{profile?.full_name || ''} ·&nbsp;ID&nbsp;{profile?.patient?.id || ''}</div>
            <div className="main__content">
              <ItemList link={itemsLink} name={itemsName} />
            </div>
          </div>
        </div>
      </main>

      <MainPopup active={popupShown} setpopupShown={setpopupShown} />
      <Footer />
    </div>
  )
}
