import React, { useContext, useEffect, useState } from 'react'
import Item from './Item'
import { MainContext } from '../../context/main';

export default function ItemList({ link, name, ...attrs }) {
  const [items, setItems] = useState([])
  const { makeRequest } = useContext(MainContext)
  const getItems = async () => {
    setItems([])
    if (!link) return
    else
      makeRequest({ url: link, accept: "application/vnd.bestdoctor", loader: true })
        .then(async res => {
          let json = await res.json()
          console.log(json)
          if (!res.ok)
            throw (json)
          setItems(json.data.map(el => ({
            id: el.number,
            title: el.title,
            content: [
              ['Номер полиса', el.number],
              ['Срок страхования', el.expire_at],
            ],
            //link: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
          })))
        })
        .catch(err => {
          console.error(err)
          setItems([])
        })
  }
  useEffect(() => {
    getItems()
  }, [link])
  return (
    <div {...attrs}>
      {
        (items && items.length) ?
          items.map(item => <Item key={item.id} item={item} />)
          :
          <div className="itembanner">
            <h2 className="itembanner__title">Не все {name} могут отображаться.</h2>
            <p className="itembanner__text">Скоро мы это исправим. При этом вы можете заявить о страховом случае.</p>
          </div>
      }
    </div>
  )
}
