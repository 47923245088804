import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import Header from '../components/header/Header'
import Step1 from '../components/Register/Step1/Step1'
import Step2 from '../components/Register/Step2/Step2'
import Step3 from '../components/Register/Step3/Step3'
import Step4 from '../components/Register/Step4/Step4'
import { MainContext } from '../context/main'
import { RegisterContext } from '../context/register'
import { useNavigate } from 'react-router-dom'
import Footer from '../components/Footer/Footer'

export default function Register({ reset }) {
  const mainContext = useContext(MainContext)
  const urls = mainContext.urls
  const setRoute = useNavigate()
  let wait_interval = useRef()
  let [context, setContext] = useState({
    nextLink: urls.authentication_url,
    step: 1,
    wait_seconds: 0,
    reset: reset
  })

  const setCounter = () => {
    wait_interval.current = setInterval(() => {
      //eslint-disable-next-line
      if (context.wait_seconds == 0) return
      context = { ...context, wait_seconds: context.wait_seconds - 1 }
      setContext({ ...context, wait_seconds: context.wait_seconds })
    }, 1000);
  };
  const resetCounter = () => {
    clearInterval(wait_interval.current);
    wait_interval.current = null;
  };



  useEffect(() => {
    setCounter()
    return () => resetCounter();
  }, []);


  const next = (new_context) => {
    if (new_context.finish) {
      if (new_context.token) {
        localStorage.setItem('token', JSON.stringify(new_context.token))
        mainContext.pushContext({ token: new_context.token })
        return setRoute('/main')
      } else return alert("Ошибка авторизации")
    }

    if (!new_context || (!new_context.nextLink && (new_context.increment !== 0))) {
      console.error('неверный new_context:\n')
      return console.error(new_context)
    }
    console.log(new_context.increment)
    if ((new_context.nextLink === context.nextLink) && (new_context.increment !== 0)) return console.error('nextLink не изменился')
    console.log('next')
    const next_step = context.step + ((new_context.increment !== undefined) ? new_context.increment : 1)
    console.log(next_step)
    context.step = next_step
    if (context.step > 2)
      resetCounter()

    context = { ...context, ...new_context, step: next_step }
    setContext({ ...context, ...new_context, step: next_step })
  }
  const stepComponent = useMemo(() => {
    switch (context.step) {
      case 1: return <Step1 next={next} />
      case 2: return <Step2 next={next} />
      case 3: return <Step3 next={next} />
      case 4: return <Step4 next={next} />
      default: return ''
    }
  }, [context.step])

  return (
    <div className='container'>
      <Header />
      <div className="container__body wrapper">
        <RegisterContext.Provider value={context}>
          {stepComponent}
        </RegisterContext.Provider>
      </div>
      <Footer />
    </div>
  )
}
