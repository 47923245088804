import React, { useContext, useEffect, useRef, useState } from 'react'
import classes from './Step1.module.sass'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import { AuthContext } from '../../../context/auth'
import { useNavigate } from 'react-router-dom'

export default function Step1({ next }) {
  const tel = useRef()
  const requestCodeUrl = useRef()
  const context = useContext(AuthContext)
  const { nextLink } = useContext(AuthContext)
  const form = useRef()
  const [urlReceived, setUrlReceived] = useState(false)
  const setRoute = useNavigate()

  const init = async () => {
    await window.proxyfetch(nextLink, {
      "method": "GET",
      "headers": {
        "Accept": "application/vnd.bestdoctor.v1-common.moby:3",
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(json => {
        try {
          let url = json.data.login_url
          setUrlReceived(true)
          if (!url) throw (new Error())

          next({ nextLink: url, increment: 0, token: json.data.token })
        } catch (err) { throw (json) }
      })
      .catch(err => console.error(err))
  }

  useEffect(() => {
    init()
  }, [])

  const sendForm = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    if (!form.current.checkValidity()) 
      return window.formMessage(form.current)

    if (!urlReceived) return console.error('Урл для входа ещё не был получен')
    const data = new FormData(form.current)
    let object = {}
    data.forEach((value, key) => object[key] = value)
    let json = JSON.stringify(object)
    let tmp_status

    window.proxyfetch(nextLink, {
      "method": "POST",
      "headers": {
        "Accept": "application/vnd.bestdoctor.v1-common.moby:3",
        "Content-Type": "application/json",
      },
      body: json
    })
      .then(async res => {
        tmp_status = res.status
        console.log(tmp_status)
        if (!res.ok) throw (await res.json()).message
        console.log(res.status)
        return res.json()
      })
      .then(json => {
        console.log(json || tmp_status)
        requestCodeUrl.current = json.data.smscode_url
        tel.current = object.phone
        context.token = json.data.token.access || context.token
        next({ token: json.data.token.access || context.token, increment: 0 })
        requestCode()
        //next({ finish: true })
      })
      .catch(err => {
        err = err || tmp_status
        console.error(err)
        alert(err)
      })

  }

  const requestCode = async (increment = 1) => {
    if (!requestCodeUrl.current) return
    console.log(context.token)
    window.proxyfetch(requestCodeUrl.current, {
      "method": "POST",
      "headers": {
        "Accept": "application/vnd.bestdoctor.v1-common.moby:3",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${context.token}`
      },
    })
      .then(async res => {
        let json = await res.json()
        if (!res.ok) {
          console.error(json)
          throw (json?.message || json?.data?.message)
        }
        return json
      })
      .then(json => {
        console.log(json)
        next({ token: context.token, nextLink: json.data.verify_url, tel: tel.current, wait_seconds: json.data.retry_after, requestCode: () => requestCode(0), increment })
      })
      .catch(err => { console.error(err); alert(err) })
  }


  return (
    <div className={`step ${classes.step1}`}>
      <h1 className="step__title">Вход в BestInsure</h1>
      <form ref={form} onSubmit={e => { e.preventDefault(); }} className="step__form">
        <Input name="phone" label="Номер телефона" pattern="\+[0-9] \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}" required="required" mask="+# (###) ###-##-##" type="text" inputMode="numeric" clearable={true} />
        <Input type="password" checkFn={false} clearable={true} required="required" label="Пароль" name="password" />
        <Button className={classes.step1__reset} onlytext={true} onClick={() => setRoute('/reset')}>Забыли пароль?</Button>
        <Button disabled={!urlReceived} className="step__button button_submit" onClick={sendForm}>Продолжить</Button>
        <Button className={classes.step1__register} onlytext={true} onClick={() => setRoute('/register')}>Зарегистрироваться</Button>
      </form>
    </div>
  )
}
