import React from 'react'
import './Item.sass'

export default function Item({ item, ...attrs }) {
  return (
    <div className="item" {...attrs}>
      <div className="item__headline">
        <div className="item__title">{item.title}</div>
        {
          item.link ?
            <a href={item.link} target="_blank" rel="noreferrer" className="item__link">Подробнее</a>
            : ''
        }
      </div>
      <div className="item__content">
        {
          item.content.map((c, i) =>
            (
              <div className="item__content-item" key={c[0] + ' - ' + c[1] + ' - ' + i}>
                <div className="item__content-item-name">{c[0]}</div>
                <div className="item__content-item-value">{c[1]}</div>
              </div>
            )
          )
        }
      </div>
    </div>
  )
}
