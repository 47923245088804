import React from 'react'
import './Popup.sass'

export default function Popup({ active, setpopupShown, size, centered, children }) {
  return (
    <div className={"popup" + (active ? " active" : "") + (size ? ` popup_${size}` : '') + (centered ? ' popup_centered' : '')} onClick={() => setpopupShown(false)}>
      <div className="popup__body" onClick={(e) => e.stopPropagation()}>
        <div className="popup__topline">
          <button className="popup__close" onClick={() => setpopupShown(false)}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1_1291)">
                <circle cx="12" cy="12" r="12" fill="#F1F2F4" />
                <path d="M11.7052 13.7677C11.8679 13.605 12.1317 13.605 12.2945 13.7677L16.5666 18.0398L18.0397 16.5667L13.7676 12.2946C13.6049 12.1319 13.6049 11.868 13.7676 11.7053L18.0397 7.43322L16.5666 5.96008L12.2945 10.2322C12.1317 10.3949 11.8679 10.3949 11.7052 10.2322L7.4331 5.96008L5.95996 7.43322L10.2321 11.7053C10.3948 11.868 10.3948 12.1319 10.2321 12.2946L5.95996 16.5667L7.4331 18.0398L11.7052 13.7677Z" fill="#41434E" />
              </g>
              <defs>
                <clipPath id="clip0_1_1291">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
        <div className="popup__inner">
          {children}
        </div>
      </div>
    </div>
  )
}
