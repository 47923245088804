import React, { useContext, useRef, useState } from 'react'
import '../Step.sass'
import './Step1.sass'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import { RegisterContext } from '../../../context/register'

export default function Step1({ next }) {
  let [tel, setTel] = useState('')
  const requestCodeUrl = useRef()
  const { nextLink, reset } = useContext(RegisterContext)
  const form = useRef()
  const sendPhone = async () => {
    if (!form.current.checkValidity()) 
      return window.formMessage(form.current)

    
    await window.proxyfetch(nextLink, {
      "method": "POST",
      "headers": {
        "Accept": "application/vnd.bestdoctor.v1-common.moby:3",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ "phone": tel.replace(/\s/g, '') })
    })
      .then(async res => {
        if (!res.ok) throw (await res.json()).message
        return res.json()
      })
      .then(json => {
        console.log(json)
        try {
          requestCodeUrl.current = json.data.allowed_steps.find(el => el.type === "challenge").url
          sendChallenge()
        } catch {
          throw (json)
        }
      })
      .catch(err => { console.error(err); alert(err) })
  }

  const sendChallenge = async (increment = 1) => {
    await window.proxyfetch(requestCodeUrl.current, {
      "method": "POST",
      "headers": {
        "Accept": "application/vnd.bestdoctor.v1-common.moby:3",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ "challenge_type": "sms" })
    }).then(async res => {
      if (!res.ok) throw (await res.json()).message
      return res.json()
    }).then(json => {
      console.log(json)
      const el = json.data.allowed_steps.find(el => el.type === "token")
      const challenge = json.data.allowed_steps.find(el => el.type === "challenge")
      if (!el)
        throw 'Не получена ссылка на получение токена'
      if (!challenge)
        throw 'Не получена ссылка challenge'
      const url = el.url

      next({ nextLink: url, tel, wait_seconds: challenge.wait_seconds, challenge: challenge.url, requestCode: () => sendChallenge(0), increment })
    })
      .catch(err => { console.error(err); alert(err); })
  }

  return (
    <div className="step step1">
      <h1 className="step__title">
        {
          !reset?
            (<span>Добро пожаловать <br className='onlydesk' />в&nbsp;BestInsure!</span>)
          : ("Сброс пароля")
        }
        </h1>
      <form ref={form} onSubmit={(e) => e.preventDefault()} className="step__form">
        <Input name="phone" label="Номер телефона" pattern="\+[0-9] \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}" required="required" mask="+# (###) ###-##-##" type="text" value={tel} changeFn={(v) => setTel(v)} inputMode="numeric" clearable={true} />
        <Button className="step__button button_submit" onClick={sendPhone}>Продолжить</Button>
      </form>
    </div>
  )
}
