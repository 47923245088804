import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.sass';
import Auth from './views/Auth';
import Main from './views/Main';
import { MainContext } from './context/main'
import { useEffect, useState } from 'react';
import Register from './views/Register';
import { bestdoctorResourcesUrl, mode, useProxy, proxy_url } from './settings.js';
import Popup from './components/UI/Popup/Popup';
import Loader from './components/Loader/Loader';


function App() {
  let [context, setContext] = useState({})
  let [response, setResponse] = useState(null)
  let [loader, setLoader] = useState(0)
  let [refreshPromise, setRefreshPromise] = useState(null)
  let [popup, setPopup] = useState({ shown: false, title: 'Ошибка', text: 'Что-то пошло не так!' })

  const setRoute = useNavigate()
  const location = useLocation();

  let getContext = async () => {
    setLoader((prev) => prev + 1)
    if (mode === "production") {
      console.log = () => false
      console.error = () => false
    }

    if (useProxy) {
      window.proxyfetch = async (url, config) => {
        const body = JSON.stringify({ url, ...config })
        setLoader((prev) => prev + 1)
        return fetch(proxy_url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: body
        })
          .finally(() => { setLoader((prev) => prev - 1) })
      }
    } else
      window.proxyfetch = (url, config) => {
        setLoader((prev) => prev + 1)
        return window.fetch(url, config).finally(() => setLoader((prev) => prev - 1))
      }
    const resources_url = bestdoctorResourcesUrl
    await window.proxyfetch(resources_url, {
      "method": "GET",
      "headers": {
        "Accept": "application/vnd.bestdoctor.v1-common.moby:3",
        "Content-Type": "application/json"
      }
    }).then(async res => {
      if (!res.ok) throw (res.status)
      return res.json()
    }).then(json => {
      response = {
        urls: {
          ...json.data
        }
      }
    }).catch(err => {
      alert('Ошибка получения адресов ресурсов')
      console.error(err);
    })

    setResponse(response)

    const updateToken = async () => {
      if (context.urls.token_url) {
        console.log('updating token...')
        let promiseResolve
        let promise = new Promise(function (resolve) {
          promiseResolve = resolve
        })
        refreshPromise = promise
        setRefreshPromise(promise)

        return await makeRequest({ url: context.urls.token_url, method: "POST", body: { refresh: context?.token?.refresh }, refresh: true })
          .then(res => res.json())
          .then(json => {
            console.log(json)
            if (!json?.data?.access || !json?.data?.refresh)
              throw (json)
            else {
              pushContext({ token: json.data })
              promiseResolve(true)
              return true
            }
          })
          .catch(err => {
            console.error(err)
            promiseResolve(false)
            return false
          }).finally(() => setTimeout(() => { refreshPromise = null; setRefreshPromise(null) }))
      }
      return false
    }

    async function waitRefreshResult() {
      console.log('await refreshPromise...')
      let a = performance.now()
      let success = await refreshPromise
      console.log(`it took ${Math.round((performance.now() - a) * 10) / 10}ms`)
      return success
    }
    const makeRequest = async ({ url, method = "GET", body, accept = "application/vnd.bestdoctor.v1-common.moby:3", json = true, refresh = false, loader = false }) => {
      if (refreshPromise && !refresh)
        if (!(await waitRefreshResult()))
          throw ('denied')

      let config = {
        "method": method,
        "headers": {
          "Accept": accept,
        }
      }
      if (json)
        config.headers["Content-Type"] = "application/json"

      if (context?.token?.access && !refresh)
        config.headers.Authorization = `Bearer ${context?.token?.access}`
      if (body) {
        if ((typeof (body) !== 'string') && json)
          body = JSON.stringify(body)
        config.body = body
      }
      if (loader)
        setLoader((prev) => prev + 1)
      return await window.proxyfetch(url, config)
        .then(async res => {
          if (!(context?.token?.access && (res.status === 401)))
            return res
          else {
            if (!refresh) {
              let updated
              if (refreshPromise) {
                updated = await waitRefreshResult()
                if (!updated)
                  throw ('denied')
              } else
                updated = await updateToken()

              if (updated)
                return makeRequest({ url, method, body, accept, json, refresh })
            }
            logout()
            return res
          }
        })
        .finally(() => loader ? setLoader((prev) => prev - 1) : false)
    }

    const updateUrls = async () =>
      await makeRequest({ url: resources_url })
        .then(async res => {
          let json = await res.json()
          console.log(json)
          if (!res.ok)
            throw (json)
          pushContext({ urls: { ...context.urls, ...json.data } })
          return json.data
        }).catch(err => {
          console.error(err)
          let str = (typeof (err) === 'string' ? err : (err?.message || err?.data?.message))
          if (str)
            alert(str)
          return {}
        })


    window.alert = (err, centered = true) => {
      let obj
      if (err === 'denied')
        return
      if (typeof (err) == 'string')
        obj = {
          title: 'Ошибка',
          text: err,
          shown: true,
          centered
        }
      else {
        if (err.title)
          obj = { ...err, shown: true }
        else {
          let str = (err?.message || err?.data?.message)
          obj = {
            title: 'Ошибка',
            text: (str && (typeof (str) == "string")) ? str : "Что-то пошло не так",
            shown: true,
            centered
          }
        }
      }
      setPopup(obj)
    }

    window.formMessage = function (form) {
      let el = document.createElement('button')
      form.appendChild(el)
      el.click()
      form.removeChild(el)
    }

    window.formStep = (e) => {
      if ((e.key !== 'Enter') || !document.activeElement || (document.activeElement.tagName !== 'INPUT') || !(document.activeElement.closest('form')))
        return
      let el = document.activeElement
      let form = el.closest('form')
      let flag = false

      for (let i = 0; i < form.elements.length; i++) {
        let cur = form.elements[i]
        if (flag) {
          if ((cur.tagName === 'INPUT') && (cur.getAttribute('type') !== 'checkbox')) {
            return cur.select()
          }
          if ((cur.tagName === 'BUTTON') && (cur.classList.contains('button_submit')))
            return cur.click()
        }
        if (cur === el) {
          flag = true
        }
      }
    }


    const pushContext = (obj) => {
      context = { ...context, ...obj }
      setContext(context)
    }

    let token = localStorage.getItem('token')
    if (token) {
      token = JSON.parse(token)
      setRoute('/main')
    }
    else {
      if (location.pathname !== '/register')
        setRoute('/auth')
    }

    const logout = () => {
      localStorage.removeItem('token')
      pushContext({ profile: null, token: null })
      setRoute('/auth')
    }

    context = { ...response, inited: response ? true : false, token, logout, setRoute, pushContext, makeRequest, updateUrls, setLoader }
    setContext(context)
    setLoader(prev => prev - 1)
  }
  useEffect(() => {
    getContext()
  }, [])

  return (
    <MainContext.Provider value={context}>
      {
        context.inited ? (
          <Routes>
            <Route path="/register" element={<Register />} />
            <Route path="/reset" element={<Register reset={true} />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/main" element={<Main />} />
          </Routes>
        )
          : ''
      }
      <Popup active={popup.shown} setpopupShown={(shown) => setPopup({ ...popup, shown })} size="small" centered={popup.centered}>
        <h2 className="popup__title">{popup.title}</h2>
        <div className="popup__text">{popup.text}</div>
      </Popup>
      <Loader active={loader} />
    </MainContext.Provider >
  );
}

export default App;
